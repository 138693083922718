import React from "react";
import { Button, EventButton, RequestButton } from "./style";
import PropTypes from "prop-types";

export default function CommonButton({
  disabled,
  onClick,
  type,
  title,
  styles,
  icon,
  addMargin,
  btnType,
  challengeDisabled,
  style,
  nextIcon
}) {

  return (
    <React.Fragment>
      {btnType === 'rounded' && 
      <Button
        disabled={disabled}
        onClick={onClick}
        styles={styles}
        style={style}
        addMargin={addMargin}
        type={type}
      >
        {icon ? icon : null}
        {title}
      </Button>}
      {btnType === "square" && 
      <RequestButton
        onClick={onClick}
        styles={styles}
        disabled={disabled}
      >
        {title}
      </RequestButton>
      }
      {btnType === "squareIcon" &&
      <EventButton
        onClick={onClick}
        styles={styles}
        disabled={disabled}
        challengeDisabled={challengeDisabled}
        style={style}
      >
        {icon ? (<span style={{marginRight:"12px"}}> {icon}</span>) : null}
        {title}
        {nextIcon ? (<span style={{marginLeft:"12px"}}> {nextIcon}</span>) : null}
      </EventButton>
      }
    </React.Fragment>
  );
}

CommonButton.propTypes = {
  disabled: PropTypes.any,
  onClick: PropTypes.func,
  title: PropTypes.string,
  styles: PropTypes.any,
  style:PropTypes.any,
  icon: PropTypes.any,
  addMargin: PropTypes.any,
  type: PropTypes.any,
  requestButton: PropTypes.bool,
  btnType : PropTypes.string,
  challengeDisabled: PropTypes.any,
  nextIcon:PropTypes.element
};
