import { DropdownButton, DropdownItem } from "react-bootstrap";
import styled, { css } from 'styled-components';
import { CustomDropDown } from "../../PeopleHomeV2/styles";

const BoldFont = 'Rubik-Bold';

const TitleContainer = styled.div`
  float: left;
  width: ${({ tileContainerStyle }) => tileContainerStyle && tileContainerStyle.width ? tileContainerStyle.width : '100%'};
  padding: ${({ tileContainerStyle }) => tileContainerStyle && tileContainerStyle.padding ? tileContainerStyle.padding : '15px 0px 10px 0px'};
  margin: ${({ tileContainerStyle }) => tileContainerStyle && tileContainerStyle.margin ? tileContainerStyle.margin : "0px"};
  position: relative;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  > div {
    float: left;
    width: 100%;
    position: relative;
    background: ${({ tileContainerStyle }) => tileContainerStyle && tileContainerStyle.background ? tileContainerStyle.background : ""};
  }
  .main{
    display: inline-block;
    width: 100%;
    >span{
      font-size: 12px;
    }
  }
  .outerDiv{
    display: flex;
    >div:first-child{
      width: 70%;
    }
    >div:last-child{
      width: 30%;
      display: flex;
      .padding{
        padding-top: 10px;
      }
      >div:first-child{
        height: 100%;
        width: 20%;
        >label{
          margin: 0;
        }
      }
      >div:last-child{
        width: 80%;
      }
    }
  }

  ${({ addMargin }) => addMargin && css`
    > div {
      margin-top: 10px;
    }
  `}

  @media (max-width: 550px) {
    padding: 30px 15px 30px;
  }

  svg {
    position: absolute;
    top: 15px;
    right: 0;
  }
  .dropdown-item{
    color:#005c87;
  }
  .dropdown-item.active, .dropdown-item:active{
    background:rgb(13, 66, 112);
    color: white;
  }
  .dropdown-item.hover, .dropdown-item:hover{
    background:rgba(0, 92, 135, 0.05);
    color: #005c87;
  }
  .dropdown-menu {
    display: ${({ display }) => display ? display : ""};
    width:${({ tileContainerStyle }) => tileContainerStyle && tileContainerStyle.width ? tileContainerStyle.width : "50%"};
    max-height: 230px;
    height: 120px;
    overflow-y: scroll;
    ${'' /* position:${({ tileContainerStyle }) => tileContainerStyle && tileContainerStyle.position ? tileContainerStyle.position : "absolute"}; */}
 >.active>a:hover {color:black}
  }

  .dropdown-menu>.active>a{
    background:#0D4270;
  }
  @media (max-width: 1000px){
    width: 100%;
  }
`;

const StyledLabel = styled.label`
  float: left;
  display: block;
  font-size: 16px;
  color: rgba(0, 47, 71, 1);
  line-height: 21px;
  font-family: Rubik;
  font-weight: 400;
  position: relative;
  pointer-events: none;
  margin-top:25px;
  margin-bottom:15px;
  display: flex;
  width: ${({ width }) => width ? width : '100%'};
  >span{
    font-size: 18px;
    color:#F6797D;
  }
  .charCount{
    display:flex;
    margin:auto;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #9c9c9c;
    margin-right:0px;
  }
  .radio{  width:auto;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #0D4270;
      display:flex;
      justify-content:flex-start;
      align-items:center;
      flex-direction:row;
      margin: auto;
    margin-right: 0px;
    }
`;

const DropdownArrow = styled.img`
float: right;
top:${({ dropdownStyle }) => dropdownStyle && dropdownStyle.top ? dropdownStyle.top : dropdownStyle && dropdownStyle.drop ? "67px" : " 60px"};
position: absolute;
right: 15px;
display:block;
z-index:5
`;

const ActivityDropdown = styled(DropdownButton)`
  ${'' /* padding: 0px 20px; */}
  display:flex;
  width: 100%;
  text-align: left;
  margin-top: 0px;
  margin: ${({ margin }) => margin};
  text-transform: capitalize;
  height: ${({ height }) => height ? height : "50px"};
  color: ${({notActive}) =>notActive? '#9c9c9c' : '#0D4270'};
  font-size:16px;
  font-family: ${({notActive}) =>notActive? 'rubik' : 'rubik-medium'};
  border: 1px solid #cdcbcb;
  cursor: ${({ disabled }) => disabled && 'not-allowed !important'};
  background:${({ background }) => background ? background:  'rgba(156,156,156,.04)'};
  >span{
    display:none;
  }
  &:active,  &:focus, &:active:focus {
    outline: none;
    background-color: white;
    box-shadow: none;
    // color: #9c9c9c;
    color: #0D4270;
    font-size:16px;
    font-family: 'rubik-medium';
    border: 1px solid #9C9C9C;
  }

  button{
    background:transparent !important;
    border:none;
    display:flex;
    align-items:center;
    width: 100%;
    ${'' /* color:#005C87; */}
  }

  .dropdown-menu.show {
    font-size: 14px;
    font-family: 'Rubik-Regular';
  }

  .dropdown-item:focus, .dropdown-item:hover{
    background: rgba(105, 194, 255, 0.1) !important;
    color: #262626;
  }

  .dropdown-item.active, .dropdown-item:active {
    background: rgb(13, 66, 112);
  }

  button:hover{
    ${'' /* color:#005C87; */}
  }

  button:focus{
    background-color: transparent !important;
  }
  button:focus-visible{
    background-color: transparent !important;
  }
  .btn.show{
    border-radius: 0px;
    color: #333;
    background-color: #e6e6e6 !important;
    border-color: #adadad;
  }
  .dropdown-toggle::after{
    display: none;
  }
  .btn:hover{
    color: #333;
    background-color: #e6e6e6 !important;
    border-color: #adadad;
    border-radius: 0px;
  }
`;

const CustomMenuItem = styled(DropdownItem)`
  width: 100% !important;
  text-transform: capitalize;
  > a {
    &:active, &:hover, &:focus {
      outline: none;
      color:black;
      background-color:rgba(105,194,255,0.1)!important;
      border-radius:6px !important;
    }
    .dropdown-item.active, .dropdown-item:active{
      background:rgb(13, 66, 112);
    }
  }
  .dropdown-item.active, .dropdown-item:active{
    background:rgb(13, 66, 112);
  }
`;

const ActivityTypeContainer = styled.div`
  width: ${({ width }) => width ? width : '40% !important'};
  padding: 0;
  float: ${({float}) => float ? float : 'none'};
  margin: ${({margin}) => margin ? margin : '0px'};
  > span {
    width: ${({ width }) => width && '100%' };
  }
  > div {
    width: ${({ noChildWidth }) => noChildWidth ? '100%' : '95%' };
    max-width: ${({ noChildWidth }) => noChildWidth ? 'unset' : '310px' };
    margin-right:  ${({ noChildWidth }) => noChildWidth ? '0px' : '5%' };
    display: ${({ display }) => display ? 'flex' : 'block'};
    float: left;
  }

  @media (max-width: 550px) {
    ${({ challengeMode }) => challengeMode && css`
      margin-top: 15px;
    `}
  }
  
  @media (max-width: 640px) {
    width: 100% !important;
    margin-top: 0
  }

  .dropdown-menu {
    width: 100%;
    height: 280px;
    overflow: scroll;
  }
`;

const FieldTitle = styled.span`
  font-size: 14px;
  margin-bottom : 15px;
  letter-spacing: 0px;
  line-height: 24px;
  color: ${({color}) => color ? color :'rgb(51,51,51)'};
  font-family: ${BoldFont};
  float: left;
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  width: ${({ fullWidth }) => fullWidth && fullWidth};
  .day {
    font-size: 18px;
    color: #159fc9;
  }
`;

const ActivityDropdownOne = styled(DropdownButton)`
  padding: 12px 10px;
  width: 100%;
  text-align: left;
  margin-top: 15px;
  color: rgb(153,153,153);
  text-transform: capitalize;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  > span {
    float: right;
    top: 20px;
    position: absolute;
    right: 10px;
    height:100px;
    overflex:scroll;
  }

  &:active, &:hover, &:focus, &:active:focus {
    outline: none;
    background-color: white;
    border-color: #ccc;
    box-shadow: none;
    color: rgb(153,153,153);
  }
`;

const CustomMenuItemOne = styled(DropdownItem)`
  width: 100%;
  text-transform: capitalize;
  > a {
    &:active, &:hover, &:focus {
      outline: none;
    }
  }
`;

const NewLabel = styled.label`
font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 20px;
text-align: center;
color: #9C9C9C;
display: flex;
margin: auto;
margin-right: 10px;
`;

const YearDropDown = styled(CustomDropDown)`
  float: right;
  align-items: center;
  margin-left: 60px;
  padding: ${({inputpadding}) => inputpadding ? inputpadding : '9px 29px'};
  width: 90px;
  height: 40px;
  background-color: rgba(105, 194, 255, 0.1);
  border-radius: 6px;
  box-shadow: none;
  margin-top: ${({role}) => (role === 'ADMIN') ? '0px' : '0px'};
  margin-right: 15px;
  padding: 10px 13px 10px 13px;
  .open>.dropdown-men{
    margin-top: -40px;
  }
  @media (max-width: 760px) and (min-width: 651px) {
     margin-top: ${({role}) => (role === 'ADMIN') ? '-62px' : ''};
  }
  @media (max-width: 650px){
    margin-top: ${({role}) => (role === 'ADMIN') ? '-54px' : '-36px'};
  }
  @media (max-width: 500px){
    margin-left: 0px;
    margin-top: -40px;
  }
  .caret{
    color: #69C2FF;
    float: left;
    margin-left :13px;
  }
`;

export { ActivityDropdown,CustomMenuItem,TitleContainer,StyledLabel , DropdownArrow, ActivityTypeContainer, FieldTitle, ActivityDropdownOne, CustomMenuItemOne, NewLabel, YearDropDown}