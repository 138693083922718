import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  ActivityDropdown,
  //   ActivityDropdownOne,
  //   ActivityTypeContainer,
  CustomMenuItem,
  StyledLabel,
  //   CustomMenuItemOne,
  //   DropdownArrow,
  //   FieldTitle,
  TitleContainer,
} from "./DropDownStyle";

export default function DropDown({
//   type,
  tileContainerStyle,
  // dropdownOpen,
  // dropdownStyle,
  label,
  isRequired,
  disabled,
  activityDropdownStyle,
  // placeholder,
  title,
  // id,
  // onClick,
  data,
  onSelect,
  onSelectParameter,
  active,
  notActive,
  itemValue,
  activeValue,
  //   displayValue,
  valueString,
  // t,
  valueIndex,
  customMenuItemStyle,
  height,
  icon,
  background
  //   value
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <React.Fragment>
      {label ? <StyledLabel style={{color: "#005C87",fontFamily: "Rubik-Regular",fontSize: "16px",fontWeight: 400,ineHeight: "20px", marginTop:"0px" }}>
        {label}
        {isRequired ? <span>*</span> : null}
      </StyledLabel> : null}
      <TitleContainer tileContainerStyle={tileContainerStyle}>
        <ActivityDropdown
          background={background || "rgba(247, 245, 235, 0.50)"}
          activityDropdownStyle={activityDropdownStyle}
          height={height}
          placeholder="Select Option"
          title={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                className="title"
                style={{
                  // minWidth: "150px",
                  color: "#005C87",fontFamily: "Rubik-Medium",fontSize: "16px",fontWeight: 500,ineHeight: "24px",
                  display:"flex",
                  alignItems:"center",
                }}
              >
                <div style={{display:"flex", marginRight:"10px"}}>{icon ? icon : ""}</div>
                <div>{title}</div>
              </div>
              <div
                style={{
                  marginLeft: "30px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="20" viewBox="0 0 12 20" fill="none" style={{ right: "17px", top:"unset" }} transform={isOpen && "rotate(90)"}>
                  <path d="M12 10c0 .358-.144.717-.432.99l-9.051 8.6a1.53 1.53 0 0 1-2.085 0 1.352 1.352 0 0 1 0-1.981L8.442 10 .431 2.391a1.352 1.352 0 0 1 0-1.98 1.529 1.529 0 0 1 2.085 0l9.052 8.599c.287.273.431.632.431.99Z" fill="#005C87"/>
                </svg>
              </div>
            </div>
          }
          id="dropdown-recurring"
          onClick={() => setIsOpen(!isOpen)}
          notActive={notActive}
          disabled={disabled}
          onSelect={(index,/*indexKey*/) => onSelect(data[index])}
        >
          {data.map((item, index) => (
            <CustomMenuItem
              customMenuItemStyle={customMenuItemStyle}
              eventKey={index}
              key={index}
              onSelect={() => onSelect(item)}
              active={
                active == (activeValue ? item[onSelectParameter[1]] : item)
              }
            >
              {itemValue
                ? `${item[onSelectParameter[valueIndex]]} ${valueString}`
                : `${item} ${valueString}`}
            </CustomMenuItem>
          ))}
        </ActivityDropdown>
      </TitleContainer>
    </React.Fragment>
  );
}

DropDown.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  dropdownOpen: PropTypes.bool,
  data: PropTypes.array.isRequired,
  onSelect: PropTypes.func,
  active: PropTypes.any,
  isRequired: PropTypes.bool,
  onSelectParameter: PropTypes.array,
  notActive: PropTypes.any,
  valueString: PropTypes.any,
  itemValue: PropTypes.bool,
  valueIndex: PropTypes.number,
  tileContainerStyle: PropTypes.obj,
  activityDropdownStyle: PropTypes.obj,
  customMenuItemStyle: PropTypes.any,
  activeValue: PropTypes.bool,
  displayValue: PropTypes.bool,
  disabled: PropTypes.any,
  value: PropTypes.any,
  height:PropTypes.string,
  icon:PropTypes.element,
  background:PropTypes.string,
};